/* eslint-disable no-unreachable */
const LIST_DASHBOARD_NOTIFS = "LIST_DASHBOARD_NOTIFS";
const MAKE_AS_READ          =  "MAKE_AS_READ";

const initialState = {
    dashboardNotifsList :[]
}

function DashboardNotifsReduccer(state=initialState,action){
    switch(action.type){
        case LIST_DASHBOARD_NOTIFS:
            return Object.assign({},state,{
                dashboardNotifsList:action.payload.data
            })

        case  MAKE_AS_READ:
          
            return state.dashboardNotifsList.map((dashNotif)=>{
                if(dashNotif._id === action.payload.data._id){
                    return {
                        ...action.payload.data,
                        hasRead:false
                    }
                }else{
                    return dashNotif
                }
            })

            return Object.assign({},state,{
                dashboardNotifsList:state.dashboardNotifsList.map((item,index)=>{
                    if(item._id === action.payload.data._id){
                        return{
                            ...item,
                            hasRead:true
                        }
                    }else{
                        return item
                    }
                })
            })

            
        default:
            return state
    }
}

export default DashboardNotifsReduccer;