import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_PAGES,AC_EDIT_PAGES,AC_HANDLE_INPUT_CHANGE } from '../../actions/pagesaction';

class EditPages extends React.Component {

  constructor(props){
    super(props);
      this.state = {
        nameError           :false,
        descriptionError    : false
      }
    this.validateform=this.validateform.bind(this);
  }
    componentDidMount() {
      const id = this.props.match.params.id;
      this.props.Viewpages(id);
      window.$(function (){
        window.$('#compose-textarea').summernote({ height: 200,focus: true });
      })
    }
    //validation
    validateform(){
      var name         = this.props.PagesReducer.pagesInfo.name;
      let slug         = this.props.PagesReducer.pagesInfo.slug;
      const content    = window.$('#compose-textarea').summernote('code');
      const id         = this.props.PagesReducer.pagesInfo.id;
      var checkdescription = 0;
      if(name){
        this.setState({nameError:false});
      }else{
        this.setState({nameError:true});
      }
      if(content) {
        this.setState({descriptionError:false});
        checkdescription=1;
      } else {
        this.setState({descriptionError:true});
      }
      slug  = name;
      slug      =  slug.replace(/[^\w\\-]+/g, "-");
      slug      =  slug.toLowerCase();
      name      =  name.charAt(0).toUpperCase() + name.slice(1);
      if(name && checkdescription ) {
        // Formdata to send Api
          const pagesFormData = {
                                name        : name,
                                slug        : slug,
                                description : content,
                                id          : id
                              }
          this.props.Editpages(pagesFormData);
      }
     }
    //onchange values
    onChangeValue(event) {
      const name          =  event.target.name;
      const value         =  event.target.value;
      this.props.HandleInputChange(name,value)
    }
  render() {
    const pagesInfo      = this.props.PagesReducer.pagesInfo;
    const name           = pagesInfo.name;
    var slug             = pagesInfo.name;
    const description    = pagesInfo.description;
    slug                 = slug.replace(/[^\w\\-]+/g, "-");
    slug                 = slug.toLowerCase();
    window.$('#compose-textarea').summernote('code',description);
      return(
          <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Edit Pages</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">Edit Pagse</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-primary">
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Page Name<span style={{color:"red"}}>*</span></label>
                              <input type="name" name="name"  className="form-control" placeholder="Enter Page Name"  value={name}  onChange={(e)=>this.onChangeValue(e)}/>
                            </div>
                              {this.state.nameError?<label style={{color:"red"}}>Pages Name is Required</label>:""}
                            <div className="form-group">
                              <label>Slug</label><span style={{color:"red"}}>*</span>
                              <input type="text" className="form-control" id="inputcode" placeholder="Slug" value={slug} onChange={(e)=> this.onChangeValue(e)}/>
                            </div>
                            <label htmlFor="exampleInputPassword1">Description<span style={{color:"red"}}>*</span></label><br/>
                            <div className="form-group">
                               <textarea type="textarea" id="compose-textarea" name="description" className="form-control" style={{height: "200px"}}/>
                               {this.state.descriptionError?<label style={{color:"red"}}>Description is Required</label>:""}
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary"  onClick={this.validateform} >Update</button>
                          </div>
                      </div>
                   </div>
                 </div>
               </div>
             </section>
           </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    PagesReducer : state.PagesReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({Viewpages: AC_VIEW_PAGES,Editpages : AC_EDIT_PAGES,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const PagesComponent = connect(mapStateToProps,mapDispatchToProps)(EditPages);

export default PagesComponent;
