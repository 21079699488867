/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React from 'react';
import { connect } from 'react-redux';
// import {Link} from "react-router-dom";
import swal from 'sweetalert';
import { bindActionCreators } from 'redux';
import { AC_ADD_CONFIGOPTION,AC_VIEW_CONFIGURATION,AC_EDIT_CONFIGOPTION,AC_EDIT_CONFIGURATION,AC_HANDLE_INPUT_CHANGE,AC_DELETE_CONFIGOPTION,AC_LIST_CONFIGOPTION} from '../../actions/configurationaction';

class EditConfiguration extends React.Component {
  constructor(props) {
     super(props);
     this.state = {
           nameError         : false,
           slugError         : false,
           descriptionError  : false,
           temp              : '',
           rowscount         : 0,
           optionname        : '',
           optiondesc        : '',
           optionvalue       : '',
           optionmandatory   : false,
           optionnameError   : false,
           optiondescError   : false,
           optionvalueError  : false,
           isNewRow          : false,
           confioptionId     : '',
           delete            : false
         }
    this.validateform       = this.validateform.bind(this);
    this.addNewConfigOption = this.addNewConfigOption.bind(this);
    this.handleConfigChange = this.handleConfigChange.bind(this);
    this.addConfigOption    = this.addConfigOption.bind(this);
   }

  componentWillMount() {
    const id = this.props.match.params.id;
    this.props.ViewConfiguration(id);
  }
   //validtion
  validateform(){
    var name              = this.props.ConfigurationReducer.configurationInfo.name;
    var slug              = this.props.ConfigurationReducer.configurationInfo.slug;
    const description     = this.props.ConfigurationReducer.configurationInfo.description;
    const id              = this.props.ConfigurationReducer.configurationInfo.id;

    if(name) {
        this.setState({nameError:false})
    } else {
        this.setState({nameError:true})
    }
    if(slug)  {
        this.setState({slugError:false})
    } else {
        this.setState({slugError:true})
    }
    if(description)  {
        this.setState({descriptionError:false})
    } else {
        this.setState({descriptionError:true})
    }
    slug = name.toLowerCase();
    slug = slug.replace(/[^\w\\-]+/g, "-");
    if(name && slug && description ) {
      // Formdata to send Api
      const configurationFormData = {
                            name          : name,
                            slug          : slug,
                            description   : description,
                            id            : id
                          }
      this.props.EditConfiguration(configurationFormData);
      //this.props.ViewConfiguration(id);
    }
  }
  //onchange values
  onChangeValue(event) {
    const name        =  event.target.name;
    const value       =  event.target.value;
    this.props.HandleInputChange(name,value)
  }

   ListOptionsRow() {
    this.setState({rowscount:this.state.rowscount+1})
   }
   deleteConfigurationoption(optionid,configid) {
     this.setState({delete:true})
    swal({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this imaginary file!",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.deleteConfigoption({optionid : optionid,configid: configid});
         }
   });
  }

  // Add Configuration options
  addConfigOption() {
    const optionname        = this.state.optionname;
    const optiondesc        = this.state.optiondesc;
    const optionvalue       = this.state.optionvalue;
    var optionmandatory     = this.state.optionmandatory;
    const id                = this.props.ConfigurationReducer.configurationInfo.id;
    const slug              = this.props.ConfigurationReducer.configurationInfo.slug;
    const optionid          = this.state.confioptionId;
    if(optionname) {
      this.setState({optionnameError:false})
    } else {
      this.setState({optionnameError:true})
    }
    if(optiondesc) {
      this.setState({optiondescError:false})
    } else {
      this.setState({optiondescError:true})
    }
    if(optionvalue) {
      this.setState({optionvalueError:false})
    } else {
      this.setState({optionvalueError:true})
    }
    if(optionid){
      if(optionname && optiondesc && optionvalue) {
        const configurationFormData = {
          optionname      : optionname,
          optiondesc      : optiondesc,
          optionvalue     : optionvalue,
          optionmandatory : optionmandatory,
          configid        : id,
          configslug      : slug,
          configoptionid  : optionid
        }
        this.setState({optionname:'',optiondesc:'',optionvalue:'',confioptionId:'',optionmandatory:'false'});
        document.getElementById("optionmandatory").checked = false;
        this.props.editConfigOption(configurationFormData);
      }
    } else {
      if(optionname && optiondesc && optionvalue) {
        const configurationFormData = {
          optionname      : optionname,
          optiondesc      : optiondesc,
          optionvalue     : optionvalue,
          optionmandatory : optionmandatory,
          configid        : id,
          configslug      : slug
        }
        this.setState({optionname:'',optiondesc:'',optionvalue:'',optionmandatory:'false'});
        document.getElementById("optionmandatory").checked = false;
        this.props.addConfigOption(configurationFormData);
      }
    }
  }

  addNewConfigOption() {
    this.setState({isNewRow:true})
  }

  handleConfigChange(event) {
    const name        =  event.target.id;
    const value       =  event.target.value;
    if(name === 'optionname') {
      if(value.length > 0 ) {
        this.setState({optionnameError:false});
      } else {
        this.setState({optionnameError:true});
      }
    }
    if(name === 'optiondesc') {
      if(value.length > 0 ) {
        this.setState({optiondescError:false});
      } else {
        this.setState({optiondescError:true});
      }
    }
    if(name === 'optionvalue') {
      if(value.length > 0 ) {
        this.setState({optionvalueError:false});
      } else {
        this.setState({optionvalueError:true});
      }
    }
    if( name === 'optionmandatory'){
      this.setState({optionmandatory:event.target.checked});
    } else {
      this.setState({[name]:value});
    }
  }
  setValue(e,optionid){
    const configoptionList   = this.props.ConfigurationReducer.configoptionList;
    const id=optionid;
    if(!e.target.getAttribute("delete")){
      const selectedOption = configoptionList.filter((configOption)=>{
        return  configOption._id === id;
      });
      this.setState({optionname:selectedOption[0].name,optiondesc:selectedOption[0].description,optionvalue:selectedOption[0].value,confioptionId:id,optionmandatory:selectedOption[0].mandatory})
      this.setState({optiondescError:false,optionnameError:false,optionvalueError:false});
      if(selectedOption[0].mandatory){
        document.getElementById("optionmandatory").checked = true;
      }
   }
  }

   render() {
     const configurationInfo  = this.props.ConfigurationReducer.configurationInfo;
     const configoptionList   = this.props.ConfigurationReducer.configoptionList;
     const configoptionCount  = this.props.ConfigurationReducer.configoptionCount;
     const name               = configurationInfo.name;
     const slug               = configurationInfo.slug;
     const rowcount           = this.state.rowscount;
     const description        = configurationInfo.description;
     var temp                 =  name.toLowerCase();
     temp                     = temp.replace(/[^\w\\-]+/g, "-");
     var configoptionlistArray    =[];
      for(var i = 0; i < configoptionCount; i++)  {
        const optionid=configoptionList[i]._id;
        if(configoptionList[i].mandatory === true)  {
          var mandatory=true
        } else {
          var mandatory=false
        }
        configoptionlistArray.push(
          <tr onClick={(e)=> this.setValue(e,optionid)} key={i} >
            <td >
              {configoptionList[i].name}
            </td >
            <td >
            {configoptionList[i].description}
            </td>
            <td >
            {configoptionList[i].value}
            </td>
            <td >
            { mandatory?<span className="badge badge-success"  style={{width:"59px"}}>Required</span>:<span className="badge badge-success" style={{backgroundColor:"#dc3545"}}>optional</span>}
            </td>
            <td >
            <a href="javascript:void(0)" className="btn btn-danger" delete='delete'  onClick={(e)=>this.deleteConfigurationoption(optionid,configurationInfo.id)}><i className="fas fa-trash" delete='delete'></i></a>
            </td>
          </tr>
        )
       }
  return(
       <>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Edit Configuration</h1>
                </div>
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Edit Configuration</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Edit Configuration</h3>
                    </div>
                      <div className="card-body">
                        <div className="form-group">
                          <label >Configuration Name</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="name" id="inputname" placeholder="Name" value={name} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.nameError?<label style={{color:'red'}}>Name is Required</label>:""}
                        </div>
                        <div className="form-group">
                          <label>Slug</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" id="inputcode" placeholder="Slug" value={temp} onChange={(e)=> this.onChangeValue(e)}/>
                        </div>
                        <div className="form-group">
                          <label>Configuration Code</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="description" id="inputcode" placeholder="Description" value={description} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.descriptionError?<label style={{color:'red'}}>Description is Required</label>:""}
                        </div>
                      </div>
                      <div className="card-footer">
                        <button type="button" className="btn btn-primary" onClick={this.validateform}>Update</button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content-header">
            <div className="container-fluid">
              <section className="content">
                <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Options</h3>
                        <div className="card-tools">
                          <div className="input-group input-group-sm">
                            <div className="input-group-append">
                              <button type="button" className="btn btn-primary" onClick={this.addNewConfigOption}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body table-responsive p-0" style={{display: this.state.isNewRow || configoptionCount>0?"block":"none"}}>
                        <table className="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Value</th>
                              <th>Mandatory</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input type="text" className="form-control" id="optionname" placeholder="Name" value={this.state.optionname} onChange={(e)=>this.handleConfigChange(e)}/>
                                {this.state.optionnameError?<span style={{color:"red"}}>Name is required</span>:""}
                              </td>
                              <td>
                                <input type="text" className="form-control" id="optiondesc" placeholder="Description" value={this.state.optiondesc} onChange={(e)=>this.handleConfigChange(e)}/>
                                {this.state.optiondescError?<span style={{color:"red"}}>Description is required</span>:""}
                              </td>
                              <td>
                                <input type="text" className="form-control" id="optionvalue" placeholder="Value" value={this.state.optionvalue} onChange={(e)=>this.handleConfigChange(e)}/>
                                {this.state.optionvalueError?<span style={{color:"red"}}>Value is required</span>:""}
                              </td>
                              <td>
                                <input type="checkbox" className="form-control" id="optionmandatory" style={{width: "27px",marginLeft:"30px"}} onChange={(e)=>this.handleConfigChange(e)}/>
                              </td>
                              <td className="text-right py-0 align-middle">
                                <div className="btn-group btn-group-sm">
                                  <a href="javascript:void(0)" className="btn btn-primary" onClick={this.addConfigOption}><i className="fas fa-plus"></i></a>
                                </div>
                              </td>
                            </tr>
                            {configoptionlistArray}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    ConfigurationReducer : state.ConfigurationReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({addConfigOption: AC_ADD_CONFIGOPTION,editConfigOption: AC_EDIT_CONFIGOPTION,ViewConfiguration: AC_VIEW_CONFIGURATION,EditConfiguration:AC_EDIT_CONFIGURATION,HandleInputChange:AC_HANDLE_INPUT_CHANGE,deleteConfigoption:AC_DELETE_CONFIGOPTION,ListConfigoptions:AC_LIST_CONFIGOPTION},dispatch)
}
const EditConfigurationComponent = connect(mapStateToProps,mapDispatchToProps)(EditConfiguration);

export default EditConfigurationComponent
