import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './../../common/api'
import { AC_VIEW_CAR, AC_UPDATE_HOST_DETAILS } from '../../actions/carlistaction';
import { AC_LIST_CARTYPE } from '../../actions/cartypeaction'
import URL from './../../common/api';
import { Image } from 'antd';
import 'antd/dist/antd.css';
import swal from 'sweetalert';
import './ViewCar.css'
import { Select } from 'antd';
const { Option } = Select;

// import { image } from 'react-dom-factories';

class ViewCar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    }
  }

  async componentDidMount() {
    // console.log('1')
    const id = this.props.match.params.id;
    await this.props.ListCarType()
    await this.props.ViewCar(id);



    // Récupération du véhicule
    // Récupération du propriétaire
  }
  onchangeValue(e) {
    const value = e.target.value
    const id = this.props.match.params.id;
    const carInfo = this.props.HostCarReducer.carInfo;
    const owner = this.props.HostCarReducer.ownerInfo
    if (value) {
      const data = {
        status: value,
        carName: carInfo.carmodel.make.brandname + " " + carInfo.carmodel.model.name,
        ownerFullName: owner.firstname + " " + owner.lastname,
        ownerEmail: owner.email,
        id: id
      }
      this.props.UpdateHostdetails(data).then(() => {
        this.props.ViewCar(id);
      })

    }
  }

  // getCartypeIdByName = (list, key) => {
  //   let id_final = ''
  //   for (let i = 0; i < list.length; i++) {
  //     if (list[i].cartype === key) {
  //       id_final = list[i]._id
  //       break
  //     }
  //   }
  //   // console.log(id_final)    
  //   return id_final
  // }

  // getCartypeNameById = (list, key) => {
  // console.log('clé', key)
  //   let name = null
  //   for (let i = 0; i < list.length; i++) {
  //     if (list[i]._id === key) {
  //       name = list[i].cartype
  //       break;
  //     }
  //   }
  //   return name
  // }


  handleChange = (value) => {
    // console.log(e.target.value)    
    const id = this.props.match.params.id; //identifiant du véhicule    
    let data = {}
    if (value) {
      data = {
        cartype: value,
        id: id
      }

    }
    
    swal({
      title: "Êtes-vous sûr?",
      text: "Cette action mettra à jour la catégorie de cette voiture!",
      icon: "warning",
      buttons: ['Fermer', 'Oui, merci'],
    })
      .then((willDelete) => {
        if (willDelete) {
          // Appel serveur                    
          this.props.UpdateHostdetails(data).then(() => {
            this.props.ViewCar(id).then(() => {
              swal("Mise à jour effectuer avec succès!", {
                icon: "success",
              });
            }).catch((e)=> {
              swal("Une erreur s'est produite !", {
                icon: "error",
              });
            });
          }).catch((e)=> {
            swal("Une erreur s'est produite !", {
              icon: "error",
            });
          })
        } else {
          swal("Mise à jour interrompue!");
        }
      });
  }
  render() {

    const carInfo = this.props.HostCarReducer.carInfo;
    const owner = this.props.HostCarReducer.ownerInfo;
    const carTypeList = this.props?.CarTypeReducer?.cartypeList
    // console.log('Le propriétaire')
    // console.log(owner)
    // console.log('Information sur véhicule')
    // console.log(carInfo)
    // var brand = "";
    var carmodel = '';
    var year = '';
    var country = '';
    // var status = "";
    var number = '';
    var mileage = '';
    var fuel = '';
    var fueltype = '';
    var seats = '';
    var doors = '';
    var price = '';
    var parking = '';
    var mobilenumber = '';
    // var countrycode = "";
    var locationspace = '';
    // var dob = "";
    let mainimage = '';
    let backimage = '';
    let sideimage = '';
    let interiorimage = ''
    // Nom    
    let firstname = '';
    let lastname = '';
    let email = '';
    let userverified = "";
    let image = "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/default_rmfapd.jpg";
    let cartype = "";
    //Récupérer la  "liste de tous les cartype

    let carTypeOptions = [];
    if (carInfo.carmodel) {

      // Information sur le véhicule
      cartype = carInfo?.carmodel?.type?.cartype;
      // brand = carInfo.carmodel.make.brand;
      carmodel = carInfo?.carmodel?.model?.name;
      year = carInfo?.number?.year;
      country = carInfo?.number?.country;
      number = carInfo?.number?.number;
      mileage = carInfo?.mileage?.mileage;
      fuel = carInfo?.fuel?.fuel;
      fueltype = carInfo?.fuel?.type;
      seats = carInfo?.facilities?.seats;
      doors = carInfo?.facilities?.doors;
      price = carInfo?.price?.price;
      parking = carInfo?.parking?.formatted_address;
      mobilenumber = carInfo?.contactnumber?.mobilenumber;
      // countrycode = carInfo?.contactnumber?.countrycode;
      locationspace = carInfo?.locationspace?.place;
      // dob = carInfo?.dob?.date + " " + carInfo?.dob?.month + "," + carInfo?.dob?.year;
      mainimage = carInfo?.carimage?.mainimage;
      sideimage = carInfo?.carimage?.sideimage;
      backimage = carInfo?.carimage?.backimage;
      interiorimage = carInfo?.carimage?.interiorimage;

      // console.log('cartype ----- ', cartype)
      // console.log(carInfo?.carmodel?.type)
      // information propriétaire
      // Nom
      firstname = owner?.firstname;
      // Prenom
      lastname = owner?.lastname
      // email
      email = owner?.email
      mobilenumber = owner?.mobilenumber
      // status vérifié ou non du propriétaire
      userverified = owner?.userverified
      if (owner?.image) {
        image = URL.IMAGEURL + owner?.image
      }

      carTypeOptions = carTypeList.map((type, index) => {
        return (
          <Option key={index} value={type._id} label={type.cartype}>
            <div className="demo-option-label-item">
              {/* <span role="img" aria-label={cartypesList[index].cartype}>
                  <img width={30}
                  height={30} src={URL.IMAGEURL_CARTYPE + cartypesList[index].image} alt="" />
                </span> */}
              {type.cartype}
            </div>
          </Option>
          // <option key={index} value={type._id} >{type.cartype}</option>

        )
      })

    }



    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>View Car List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">View Car List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="card-header" style={{ backgroundColor: "#007bff" }}>
                <h3 className="card-title" style={{ color: "white" }}>Permanent Address</h3>
                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse"><i className="fas fa-minus"></i></button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove"><i className="fas fa-times"></i></button>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="container">
                    <h3>Propriétaire</h3>
                    <div className="mycard p-3 horizontal">
                      <div className="card-image">
                        <img src={image} alt="" />
                      </div>
                      <div className="card-stacked">
                        <div className="card-content">
                          {owner && firstname && lastname && <h3 className='lead'>{firstname} {lastname} </h3>}
                          {owner && mobilenumber && <p>N° téléphone: {mobilenumber}</p>}
                          {owner && email && <p>Email: {email}</p>}

                          {owner && userverified && <p className='text-success'> Compte Vérifié <i className="fas fa-user-check"></i> </p>}
                          {owner && !userverified && <p className='text-warning'>Compte non Vérifié <i className="far fa-user-alt-slash"></i></p>}
                        </div>


                      </div>

                    </div>
                  </div>
                  <div className="container">
                    <h3>Véhicule en image</h3>
                    {/* <img src={URL.IMAGEURL+mainimage} alt="" width={60}/> */}
                    {!mainimage && <div style={{ border: "1px dashed #ddd", borderRadius: "10px", fontSize: "12px", fontWeight: 500 }} className="px-3 py-4"><img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/ProjectImage/images/no-photos_wlglyj.png" alt="noimage" /> Aucune image ajoutée pour l'instant</div>}
                    {(mainimage || backimage || sideimage) && <Image.PreviewGroup>
                      {mainimage && <Image width={200} height={200} style={{ backgroundSize: "cover", objectFit: "cover" }} src={URL.IMAGEURL + mainimage} />}
                      {backimage && <Image
                        width={200}
                        src={URL.IMAGEURL + backimage}
                        height={200}
                      />}
                      {sideimage && <Image
                        width={200}
                        src={URL.IMAGEURL + sideimage}
                        height={200}
                      />}
                      {interiorimage && <Image
                        width={200}
                        src={URL.IMAGEURL + interiorimage}
                        height={200}
                      />}
                    </Image.PreviewGroup>}
                  </div>
                  <hr />
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="inputCarModel">Car Model</label>
                      <input type="text" id="inputCarModel" disabled className="form-control" name="carmodel" value={carmodel} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Year</label>
                      <input type="inputCarModel" id="inputYear" disabled className="form-control" name="year" value={year} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Car Mileage</label>
                      <input type="text" disabled className="form-control" name="mileage" value={mileage} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Fuel Type</label>
                      <input type="text" disabled className="form-control" name="fueltype" value={fueltype} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Total Doors</label>
                      <input type="text" disabled className="form-control" name="doors" value={doors} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Parkin</label>
                      <input type="text" disabled className="form-control" name="checkinaddress" value={parking} />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="inputDescription">Country Code</label>
                      <input type="text" disabled className="form-control" name="countrycode" value={countrycode} />
                    </div> */}
                    {/* <div className="form-group">
                      <label htmlFor="inputDescription">Date-Of-Birth</label>
                      <input type="text" disabled className="form-control" name="dob" value={dob} />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="inputDescription">Car Price</label>
                      <input type="text" disabled className="form-control" value={price} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="inputCarModel">Car Model</label>
                      <input type="text" id="inputCarModel" disabled className="form-control" name="carmodel" value={carmodel} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Car Number</label>
                      <input type="text" disabled className="form-control" name="number" value={number} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Car Fuel</label>
                      <input type="text" disabled className="form-control" name="fuel" value={fuel} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Total Seats</label>
                      <input type="text" disabled className="form-control" name="seats" value={seats} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputDescription">Car Price</label>
                      <input type="text" disabled className="form-control" name="price" value={price} />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="inputDescription">Contact Number</label>
                      <input type="text" disabled className="form-control" name="mobilenumber" value={mobilenumber} />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="inputDescription">Location Space</label>
                      <input type="text" disabled className="form-control" name="locationspace" value={locationspace} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputCountry">Country</label>
                      <input type="text" id="inputCountry" disabled className="form-control" name="country" value={country} />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="inputCartype">Type de véhicule</label>
                      <input type="text" id="inputCartype"  className="form-control" name="cartype" value={cartype} />
                    </div> */}
                  </div>
                </div>
              </div>
              <hr />
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="inputCarType">Définir une catétorie</label>
                      <div className=''>

                        <Select
                          // loading={true}
                          style={{
                            width: '100%',
                            
                          }}
                          id="type"
                          name="type"
                          // status={this.state.error ? 'error' : "" }                      
                          // defaultValue={cartype}
                          placeholder="Sélectionner une catégorie"
                          onChange={this.handleChange}
                          optionLabelProp="label"
                        >
                          {carTypeOptions}

                        </Select>
                      </div>


                      {/* <select name="cartype" className='form-control' id="inputCarType" onChange={this.handleChange}>
                    {carTypeOptions}
                   </select> */}


                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      {cartype ? 
                      <div className="callout callout-success" style={{ marginTop: "20px" }}>
                        <h6>{cartype}</h6>
                      </div> :
                      <div className="callout callout-danger" style={{ marginTop: "20px" }}>
                      <h6>Non définie</h6>
                    </div>
                    }                      
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="inputCarModel">Car Approval</label>
                      <select name="status" className="form-control" onChange={(e) => this.onchangeValue(e)}>
                        {(carInfo.status === 2) ? <option value="" selected={carInfo.status === 2}>Select</option> : ""}
                        <option value={1} selected={carInfo.status === 1}>Accept</option>
                        <option value={0} selected={carInfo.status === 0}>Decline</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      {carInfo.status === 2 ? <div className="callout callout-warning" style={{ marginTop: "20px" }} >
                        <h6>Pending!</h6>
                      </div> : ""}
                      {carInfo.status === 0 ? <div className="callout callout-danger" style={{ marginTop: "20px" }}>
                        <h6>Declined!</h6>
                      </div> : ""}
                      {carInfo.status === 1 ? <div className="callout callout-success" style={{ marginTop: "20px" }} >
                        <h6>Accepted!</h6>
                      </div> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer,
    CarTypeReducer: state.CarTypeReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewCar: AC_VIEW_CAR, UpdateHostdetails: AC_UPDATE_HOST_DETAILS, ListCarType: AC_LIST_CARTYPE }, dispatch)
}
const ViewCarComponent = connect(mapStateToProps, mapDispatchToProps)(ViewCar);

export default ViewCarComponent;
