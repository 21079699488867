/* eslint-disable no-native-reassign */
const LIVEURL = "https://api.monbolide.com/";
// const LIVEURL = "https://monbolide-server.herok/";
// export const LIVEURL = "http://localhost:5000/";

const IMAGEURL =
"https://monbolide.s3.eu-north-1.amazonaws.com/";
const URL_TO_DOWNLOAD = "https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/fl_attachment/"
const ROOTURL = LIVEURL+'api/v1/';
const API = {
  AddFaq                  : ROOTURL+'faq/addFaq',
  ListFaq                 : ROOTURL+'faq/listFaq',
  ViewFaq                 : ROOTURL+'faq/viewFaq',
  UpdateFaqStatus         : ROOTURL+'faq/updateFaqStatus',
  EditFaq                 : ROOTURL+'faq/editFaq',
  DeleteFaq               : ROOTURL+'faq/deleteFaq',
  GetAdminSettings        : ROOTURL+'settings/getSiteSettings',
  UpdateAdminSettings     : ROOTURL+'settings/updateSettings',
  ChangePassword          : ROOTURL+'settings/changePassword',
  ListNewsLetter          : ROOTURL+'newsletter/listNewsletter',
  AddNewsLetter           : ROOTURL+'newsletter/addNewsletter',
  ViewNewsLetter          : ROOTURL+'newsletter/viewNewsletter',
  EditNewsLetter          : ROOTURL+'newsletter/editNewsletter',
  DeleteNewsLetter        : ROOTURL+'newsletter/deleteNewsletter',
  AddCountry              : ROOTURL+'country/addCountry',
  ListCountry             : ROOTURL+'country/listCountry',
  ViewCountry             : ROOTURL+'country/viewCountry',
  EditCountry             : ROOTURL+'country/editCountry',
  DeleteCountry           : ROOTURL+'country/deleteCountry',
  UpdateCountryStatus     : ROOTURL+'country/updateCountryStatus',
  ListCar                 : ROOTURL+'carlist/listCar',
  ViewCar                 : ROOTURL+'carlist/viewCar',
  EditCar                 : ROOTURL+'carlist/editCar',
  DeleteCar               : ROOTURL+'carlist/deleteCar',
  UpdateHostDetail        : ROOTURL+'carlist/updateHostDetail',
  PastTrip                : ROOTURL+'trips/tripsByStatus',
  NewTrip                 : ROOTURL+'trips/tripsByStatus',
  ViewTrip                : ROOTURL+'trips/viewTrips',
  TripList                : ROOTURL+'trips/tripsByStatus',
  AcceptTrips             : ROOTURL+'siteTrips/confirmTrips',
  RentList                : ROOTURL+'rentedcar/rentByStatus',
  ViewRentedCar           : ROOTURL+'rentedcar/viewRentedCar',
  AddLanguage             : ROOTURL+'language/addLanguage',
  ListLanguage            : ROOTURL+'language/listLanguage',
  UpdateLanguageStatus    : ROOTURL+'language/updateLanguageStatus',
  ViewLanguage            : ROOTURL+'language/viewLanguage',
  EditLanguage            : ROOTURL+'language/editLanguage',
  DeleteLanguage          : ROOTURL+'language/deleteLanguage',
  ListSubscription        : ROOTURL+'subscriptions/listSubscribers',
  AddCarType              : ROOTURL+'cartypes/addCarType',
  UpdateCarTypeStatus     : ROOTURL+'cartypes/updateCarTypeStatus',
  ListCarType             : ROOTURL+'cartypes/listCarTypes',
  ViewUser                : ROOTURL+'user/viewUser',
  GetParking                : ROOTURL+ 'user/getOwnerParking',
  ViewOwner               : ROOTURL+'user/viewOwner',
  EditUser                : ROOTURL+'user/editUser',
  DeleteUser              : ROOTURL+'user/deleteUser',
  ListUser                : ROOTURL+'user/listUser',
  ListOwner                : ROOTURL+'user/listOwner',
  ViewCarType             : ROOTURL+'cartypes/viewCarType',
  EditCarType             : ROOTURL+'cartypes/editCarType',
  DeleteCarType           : ROOTURL+'cartypes/deleteCarType',
  AddBrand                : ROOTURL+'brand/addBrand',
  ListBrand               : ROOTURL+'brand/listBrands',
  UpdateBrandStatus       : ROOTURL+'brand/UpdateBrandStatus',
  ViewBrand               : ROOTURL+'brand/viewBrand',
  EditBrand               : ROOTURL+'brand/editBrand',
  DeleteBrand             : ROOTURL+'brand/deleteBrand',
  ListyourCar             : ROOTURL+'listyourcar/addlistyourCar',
  EmailSubscription       : ROOTURL+'subscriptions/postMail',
  Authorization           : ROOTURL+'settings/isauth',
  ForgotPassword          : ROOTURL+'settings/forgotPassword',
  RecoverPassword         : ROOTURL+'settings/recoverPassword',
  AddConfiguration        : ROOTURL+'configuration/addConfiguration',
  ListConfiguration       : ROOTURL+'configuration/listConfiguration',
  ViewConfiguration       : ROOTURL+'configuration/viewConfiguration',
  EditConfiguration       : ROOTURL+'configuration/editConfiguration',
  DeleteConfiguration     : ROOTURL+'configuration/deleteConfiguration',
  AddConfigoption         : ROOTURL+'configuration/addConfigoption',
  EditConfigoption        : ROOTURL+'configuration/editConfigoption',
  ListConfigoption        : ROOTURL+'configuration/listConfigoption',
  DeleteConfigoption      : ROOTURL+'configuration/deleteConfigoption',
  AddCarfeatures          : ROOTURL+'carfeatures/addCarfeatures',
  ListCarfeatures         : ROOTURL+'carfeatures/listCarfeatures',
  UpdateCarFeaturesStatus : ROOTURL+'carfeatures/updateCarFeaturesStatus',
  ViewCarfeatures         : ROOTURL+'carfeatures/viewCarfeatures',
  EditCarfeatures         : ROOTURL+'carfeatures/editCarfeatures',
  DeleteCarfeatures       : ROOTURL+'carfeatures/deleteCarfeatures',
  AddNotification         : ROOTURL+'notifications/addNotification',
  ListNotification        : ROOTURL+'notifications/listNotification',
  DeleteNotification      : ROOTURL+'notifications/deleteNotification',
  ViewNotification        : ROOTURL+'notifications/viewNotification',
  EditNotification        : ROOTURL+'notifications/editNotification',
  AddPages                : ROOTURL+'pages/addPages',
  ListPages               : ROOTURL+'pages/listPages',
  ViewPages               : ROOTURL+'pages/viewPages',
  EditPages               : ROOTURL+'pages/editPages',
  DeletePages             : ROOTURL+'pages/deletePages',
  DeleteModeloption       : ROOTURL+'brand/deleteModeloption',
  AddModeloption          : ROOTURL+'brand/addModeloption',
  EditModeloption         : ROOTURL+'brand/editModeloption',
  ListModeloption         : ROOTURL+'brand/listModeloption',
  AddPayementgateway      : ROOTURL+'payementgateway/addPayementGateway',
  DeletePayementgateway   : ROOTURL+'payementgateway/deletePayementGateway',
  ListPayementgateway     : ROOTURL+'payementgateway/listPayementGateway',
  ViewPayementgateway     : ROOTURL+'payementgateway/viewPayementGateway',
  UpdatePaymentGatewayStatus : ROOTURL+'payementgateway/updatePaymentGatewayStatus',
  EditPayementgateway     : ROOTURL+'payementgateway/editPayementGateway',
  AddCurrency             : ROOTURL+'currency/addCurrency',
  ListCurrency            : ROOTURL+'currency/listCurrency',
  ViewCurrency            : ROOTURL+'currency/viewCurrency',
  EditCurrency            : ROOTURL+'currency/editCurrency',
  UpdateCurrencyStatus    : ROOTURL+'currency/updateCurrencyStatus',
  DeleteCurrency          : ROOTURL+'currency/deleteCurrency',
  UpdateUserVerify        : ROOTURL+'user/updateUserVerify',
  GetCarDetails           : ROOTURL+'user/getCarDetails',
  UpdateLicense           : ROOTURL+'user/updateLicense',
  GetOwner                : ROOTURL+ 'carlist/getOwnerInfo',
  // ENVOYER LES EMAILS PAR AD
  SendEmailCampaign       : ROOTURL+ 'admin/sendemailcampaign/sendemailcampaign',

  // LINES BY TRIEL
  DashboardNotifications  : ROOTURL+'admin/notification/all_notif',
  UpdateDashNotif : ROOTURL+'admin/notification/edit_notif'

}
export default URL = {
  API     : API,
  LIVEURL : LIVEURL,
  IMAGEURL: IMAGEURL,
  URL_TO_DOWNLOAD: URL_TO_DOWNLOAD
}
