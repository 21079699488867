/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { AgGridReact } from 'ag-grid-react';
import {  Redirect } from "react-router-dom";
import { AC_LIST_CAR, AC_DELETE_CAR, AC_UPDATE_HOST_DETAILS } from '../../actions/carlistaction';

class CarList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '120',
          cellStyle: function (params) {
            if(params.data.carmodel.type)
              return { 'font-size': '12px', 'margin-top': '5px' };
            return { 'font-size': '12px', 'margin-top': '5px', "color": "red" };
          },
          
        },
        {
          headerName: "Car model", field: "carmodel.model.name", width: '220',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: "Propriétaire", field: "userId.mobilenumber", width: '150',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: "Immatriculation", field: "number.number", width: '150',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: "Year", field: "number.year", width: '150',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: "Contact", field: "contactnumber.mobilenumber", width: '150',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: "Country", field: "number.country", width: '150',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: "Status", field: "status", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.status === 1) {
              return '<span class="badge badge-success">Approved</span>';
            } else if (params.data.status === 2) {
              return '<span class="badge badge-success" style="background-color: #ffc107!important;color:black;" data-action-type="Status">Pending</span>';
            } else {
              return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Declined</span>';
            }
          }
        },
        {
          headerName: "Enregistré depuis", field: "registrationPlatform", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.registrationPlatform === 'mobile') {
              return `<span class="badge badge-success">${params.data.registrationPlatform}</span>`;
            } 
            else {
              return `<span class="badge badge-primary" >${params.data.registrationPlatform}</span>`;
            }
          }
        },
        // { headerName: 'Actions', width: "100",sortable: false,filter:false,
        //    template:
        //    `<div>
        //    <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Approval">
        //    Verify
        //    </div>
        //    </div>`,
        //    cellStyle: function(params) {
        //           return {'margin-top' :'8px'};
        //   },
        // },
        {
          headerName: 'Actions', width: "300", sortable: false, filter: false,
          template:
            `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           View
           </div>
           <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
           Delete
           </div>
           </div>`,
          cellStyle: function (params) {
            return { 'margin-top': '8px' };
          },
        },

      ],
    }
  }
  deleteCar(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.props.DeleteCar(id);
        } else {
        }
      });
  }

  componentDidMount() {
    this.props.ListCar();
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event => {
    const rowValue = event.event.target;
    const id = event.data._id;
    const value = rowValue.getAttribute('data-action-type');
    if (value === 'View') {
      this.setState({ redirect: 'View', id: event.data._id })
    }
    if (value === 'Edit') {
      this.setState({ redirect: 'Edit', id: event.data._id })
    }
    if (value === 'Delete') {
      this.deleteCar(event.data._id)
    }
    // if(value=='Approval'){
    //   const data = {
    //     status : 1,
    //     id     : id
    //   }
    //   this.props.UpdateHostdetails(data)
    // }
  }
  render() {
    const carListCount = this.props.HostCarReducer.carListCount;    
    const carList = this.props.HostCarReducer.carList;   
    console.log(carList[0]) 
    const carListArray = [];

    if (this.state.redirect === 'Edit') {
      return <Redirect push to={'/EditCar/' + this.state.id} />
    }
    if (this.state.redirect === 'View') {
      return <Redirect push to={'/ViewCar/' + this.state.id} />
    }
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Car List </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Car List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">List of Cars</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
              <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e) => this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize

                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  defaultColDef={{ filter: true, floatingFilter: true }}
                  rowData={carList}>
                </AgGridReact>
              </div >

              <div id="myTable"></div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    HostCarReducer: state.HostCarReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ListCar: AC_LIST_CAR, DeleteCar: AC_DELETE_CAR, UpdateHostdetails: AC_UPDATE_HOST_DETAILS }, dispatch)
}
const CarListComponent = connect(mapStateToProps, mapDispatchToProps)(CarList);

export default CarListComponent;
