/* eslint-disable no-unused-vars */
import React from 'react';
import {Link,Redirect  } from "react-router-dom";
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import swal from 'sweetalert';
import { bindActionCreators } from 'redux';
import {AC_LIST_BRAND,AC_DELETE_BRAND,AC_UPDATE_BRANDSTATUS} from '../../actions/brandaction';
import API from '../../common/api';

class ListBrand extends React.Component {
  constructor(props) {
    super(props);
    const url=API.LIVEURL;
    this.state = {
    columnDefs :[
      { "header" : "#",field:"#", valueGetter :"node.rowIndex+1",width:"120",
        cellStyle: function(params) {
             return {'font-size': '16px','margin-top': '5px'};
       }},
      { "header" : "brandname", field :"brandname",width:"250",
        cellStyle: function(params) {
             return {'font-size': '16px','margin-top': '5px'};
       }},
      { "header" : "logo", field :"logo",width:"250",
        cellRenderer: function (params) {
        var logo = url.concat("" ,params.value);
        return '<img src='+logo+' alt="Image not found" style="width: 80%; height: 100%;"/>';
       }
      },
      { "header" : "status", field :"status",width:"300",
        cellStyle: function(params) {
             return {'font-size': '16px','margin-top': '5px'};
        },
        cellRenderer: function(params) {
         if(params.data.status) {
           return '<span class="badge badge-success" data-action-type="Status">Active</span>';
         } else {
           return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Inactive</span>';
         }
       }
      },
      { headerName: 'Actions', width: "300",sortable: false,filter:false,
        template:
           `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           View
           </div>
           <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Edit">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Edit"></i>
           Edit
           </div>
           <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
           Delete
           </div>
           </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
   ]
  }
  }
    componentDidMount(){
     this.props.BrandList();
   }
  deleteBrand(id) {
    swal({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this imaginary file!",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.DeleteBrand(id);

         } else {
           console.log('-=-=-=-=-=cancel-=-=-=-=',);
         }
   });
  }

  updateStatus(event){
    const data = {
       id     : event._id,
       status : !event.status
    }
    this.props.UpdateBrandStatus(data);
  }
   onSortChanged = e => {
   e.api.refreshCells();
  }
   onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
    if(value === 'Edit'){
      this.setState({redirect:'Edit',id:event.data._id})
    }
    if(value === 'Delete'){
      this.deleteBrand(event.data._id)
    }
    if(value === 'Status'){
      this.updateStatus(event.data);
    }
  }

  render() {
    const brandCount     = this.props.BrandReducer.brandCount;
    const brandList       = this.props.BrandReducer.brandList;
    const brandListArray = [];
    if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/editBrand/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/viewBrand/'+this.state.id}/>
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>List Brand</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Brand Name</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Brand Name</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div className="ag-theme-balham" style={ {height: '600px', width: 'auto'} }>
              <AgGridReact
                  rowHeight={100}
                  rowClass ={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  suppressCellSelection={true}
                  enableSorting
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={{filter:true,floatingFilter:true}}
                  rowData={brandList}>
              </AgGridReact>
            </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    BrandReducer : state.BrandReducer
  };

}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({UpdateBrandStatus:AC_UPDATE_BRANDSTATUS,BrandList:AC_LIST_BRAND,DeleteBrand:AC_DELETE_BRAND},dispatch);
}
const BrandListComponent = connect(mapStateToProps,mapDispatchToProps)(ListBrand);

export default BrandListComponent;
