/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_CONFIGOPTION} from '../../actions/configurationaction';

class Addconfigoption extends React.Component {

  constructor(props) {
     super(props);
     this.state = {
                     rowscount              : 0,
                     optionname             : '',
                     optiondesc             : '',
                     optionvalue            : '',
                     optionmandatory        : false,
                     optionnameError        : false,
                     optiondescError        : false,
                     optionvalueError       : false,
                   }
   }
  addNewOptionsRow() {
    this.setState({rowscount:this.state.rowscount+1})
  }
  addConfigurationOption() {
    const optionname       = this.state.optionname;
    const optionvalue      = this.state.optionvalue;
    const optiondesc       = this.state.optiondesc;
    const optionmandatory  = this.state.optionmandatory;
    const configid         = this.props.match.params.id;
    if(optionname) {
      this.setState({optionnameError:false})
    } else {
          this.setState({optionnameError:true})
      }
    if(optiondesc)  {
          this.setState({optiondescError:false})
    } else {
          this.setState({optiondescError:true})
      }
    if(optionvalue)  {
          this.setState({optionvalueError:false})
    } else {
          this.setState({optionvalueError:true})
      }
    const option_name   =  optionname.charAt(0).toUpperCase() + optionname.slice(1);
    const option_desc   =  optiondesc.charAt(0).toUpperCase() + optiondesc.slice(1);
    const optionsForm = {
      optionname      : option_name,
      optionvalue     : optionvalue,
      optiondesc      : option_desc,
      optionmandatory : optionmandatory,
      configid        : configid
    }
    if(optionname && optionvalue && optiondesc){
     this.setState({rowscount:this.state.rowscount+1})
      this.props.addConfigOption(optionsForm)
    }
  }
  handleInputChange(e) {
    const name  = e.target.name;
    const value = e.target.value;
    if(name==="optionname") {
      if(value) {
        this.setState({optionname:value,optionnameError:false})
      } else {
        this.setState({optionname:value,optionnameError:true})
      }
    }
    if(name==="optiondesc") {
      if(value) {
      this.setState({optiondesc:value,optiondescError:false})
      } else {
      this.setState({optiondesc:value,optiondescError:true})
      }
    }
    if(name==="optionvalue") {
      if(value) {
        this.setState({optionvalue:value,optionvalueError:false})
      } else {
        this.setState({optionvalue:value,optionvalueError:true})
      }
    }
    if(name ==="optionmandatory") {
       this.setState({optionmandatory:e.target.checked})
    }
  }

   render() {
     const configoptionlist   = this.props.ConfigurationReducer.configoptionList;
     const rowcount           = this.state.rowscount;
     const configoptionarray  = [];
     for(var i=0;i<rowcount;i++)
     {
      configoptionarray.push(
     <tr key={i}>
        <td>
          <input type="text" name="optionname" placeholder="Name" className="form-control" style={{width: "210px",border:(this.state.optionnameError)?"2px solid red":""}}  onChange={(e)=>this.handleInputChange(e)}/>
        </td>
        <td>
          <input type="text"  name="optiondesc" className="form-control" placeholder="Description" style={{width: "210px",border:(this.state.optiondescError)?"2px solid red":""}}  onChange={(e)=>this.handleInputChange(e)}/>
        </td>
        <td>
          <input type="number" name="optionvalue" className="form-control" placeholder="Value" style={{width: "210px",border:(this.state.optionvalueError)?"2px solid red":""}}  onChange={(e)=>this.handleInputChange(e)}/>
        </td>
        <td>
          <input type="checkbox" name="optionmandatory" className="form-control"placeholder="Mandatory" style={{width: "210px"}}  onChange={(e) => this.handleInputChange(e)}/>
        </td>
        <td class="text-right py-0 align-middle">
          <div class="btn-group btn-group-sm">
            <a href="javascript:void(0)" class="btn btn-info"  onClick={(e)=>this.addConfigurationOption(e)} style={{marginRight:"18px"}} ><i class="fas fa-plus"></i></a>
          </div>
        </td>
      </tr>
      )
     }
     return(
       <>
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Add Configoption</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Add Configoption</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
      {/* <div className="content-wrapper" style={{marginTop:"-650px"}}> */}
      <div style={{float:"right",marginRight:"35px"}}>
        <div >
          <button type="button" className="btn btn-primary" onClick={(e)=>this.addNewOptionsRow(e)}>Add Options</button>
        </div>
      </div>
      <section className="content-header" style={{display: this.state.rowscount > 0 ? "block" : "none"}}>
        <div className="container-fluid">
          <h1 class="card-title">Options</h1>
        </div>
        <div class="card-body p-0">
          <table class="table">
            <thead>
              <tr>
                <th style={{textAlign: "center"}}>Name</th>
                <th style={{textAlign: "center"}}>Description</th>
                <th style={{textAlign: "center"}}>Value</th>
                <th style={{textAlign: "center"}}>Mandatory</th>
                <th style={{textAlign: "center"}}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {configoptionarray}
            </tbody>
          </table>
        </div>
        </section>
      </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer : state.ConfigurationReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addConfigOption:AC_ADD_CONFIGOPTION},dispatch)
}
const AddconfigoptionComponent = connect(mapStateToProps,mapDispatchToProps)(Addconfigoption);

export default AddconfigoptionComponent;
