/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Imagevalidation } from '../../common/validate';
import { AC_VIEW_CARFEATURES,AC_EDIT_CARFEATURES,AC_HANDLE_INPUT_CHANGE} from '../../actions/carfeaturesaction';
import API from '../../common/api';

class EditCarfeatures extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
    name        : '',
    icon        : '',
    status      : '',
    statuserror : '',
    nameerror   : false,
    iconerror   : false,
    iconcheck   : false,
    iconvalue   : false,
    iconSrc     : 'dist/img/audi.png'
  }
  this.validateForm=this.validateForm.bind(this);
 }
 componentDidMount() {
 const id = this.props.match.params.id;
 this.props.ViewCarfeatures(id);
}

 validateForm() {
   const carfeaturesInfo    = this.props.CarfeaturesReducer.carfeaturesInfo;
   const name               = carfeaturesInfo.name;
   const icon               = carfeaturesInfo.icon;
   const iconcheck          = this.state.iconcheck;
   const status             = carfeaturesInfo.status;
   const id                 = carfeaturesInfo.id;
  //const statuserror = this.state.statuserror;
    if(name) {
      this.setState({nameerror : false})
   }
   else{
      this.setState({nameerror : true})
   }
   const carfeatures_name   =  name.charAt(0).toUpperCase() + name.slice(1);
    if(name && !iconcheck) {
      const formData = new FormData();
      formData.append('icon', icon);
      formData.append('id', id);
      formData.append('name', carfeatures_name);
      formData.append('status', status);
      this.props.EditCarfeatures(formData);
   }
 }
 //Onchange events
 onChangeValue(event){
   const name=event.target.name;
   var value=event.target.value;
   if(name ==='name'){
    this.setState({name:value,nameerror:false})
  }

   // icon
   if(name==='icon'){
     this.setState({ icondata : event.target.files[0] })
        var file            =  event.target.files[0];
        const iconvalidate   =  Imagevalidation(file);
        if(iconvalidate){
        var reader          = new FileReader();
        var url             = reader.readAsDataURL(file);
        reader.onloadend    = function(e){
        this.setState({iconSrc : [reader.result],iconvalue:true,iconcheck:false})
        }.bind(this);
        }
        else{
          this.setState({icon:value,iconcheck:true,})
          this.setState({iconSrc : ""})
        }
        value = event.target.files[0];
   }
   this.props.HandleInputChange(name,value)
 }
render() {

  const carfeaturesInfo  = this.props.CarfeaturesReducer.carfeaturesInfo;
  const name             = carfeaturesInfo.name;
  const icon             = carfeaturesInfo.icon;
  const status           = carfeaturesInfo.status;
  const LIVEURL          = API.LIVEURL;

  return(
    <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
          <h1>Edit CarFeatures</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Edit CarFeatures</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit CarFeatures</h3>
              </div>
              <form>
              <div className="card-body">
                    <div className="form-group">
                       <label>Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="name" placeholder="Enter Carfeatures Name"  value={name} pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.nameerror ? <label style={{color:"red"}}>Carfeatures Name is Required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Icon</label><span style={{color:"red"}}>*</span>
                    <div className="input-group">
                       <div className="custom-file">
                           <input type="file" name="icon" className="custom-file-input" id="exampleInputFile" value='' onChange={(e)=>this.onChangeValue(e)}/>
                           <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                       </div>
                       <div className="input-group-append">
                           <button className="input-group-text" id="">Upload</button>
                       </div>
                    </div>
                        {this.state.iconcheck? <label style={{color:'red'}}>Icon format is Invalid</label>:""}
                    <div>
                      {this.state.iconvalue? <img src={this.state.iconSrc} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/> : <img src={LIVEURL+icon} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>}
                     </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                    <select name = "status" className="form-control"  onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1" selected={status === 1}>Active</option>
                       <option value = "0" selected={status === 0} >Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                       <button type="button" className="btn btn-primary" onClick={this.validateForm}>Update</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CarfeaturesReducer : state.CarfeaturesReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCarfeatures: AC_VIEW_CARFEATURES,EditCarfeatures:AC_EDIT_CARFEATURES,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditCarfeaturesComponent = connect(mapStateToProps,mapDispatchToProps)(EditCarfeatures);

export default EditCarfeaturesComponent;
