import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import API from '../../common/api';
import { AC_VIEW_CARTYPE} from '../../actions/cartypeaction';

class ViewCarType extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.viewCartype(id);
  }
  render(){
      const carInfo = this.props.CarTypeReducer.cartypeInfo;
      const cartype = carInfo.cartype;
      const image   = carInfo.image;
      const status  = carInfo.status;
      const LIVEURL = API.LIVEURL;

      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                <h1>View Car Types</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">View Car Types</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">View Car Types</h3>
                    </div>
                    <form>
                      <div className="card-body">
                        <div className="form-group">
                           <label>Car Type</label>
                           <input type="text" className="form-control" name="cartype" placeholder="Enter Car Type" value={cartype} disabled/>
                        </div>
                        <label htmlFor="exampleInputFile">Image</label>
                        <div>
                           <img src={LIVEURL+image} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>
                         </div>
                        <div className="form-group">
                        <label htmlFor="exampleInputFile">Status</label>
                        <select name = "status"  className="form-control" disabled>
                          <option  selected={status === 1}>Active</option>
                          <option  selected={status === 0}>InActive</option>
                       </select>
                         </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    CarTypeReducer : state.CarTypeReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({viewCartype: AC_VIEW_CARTYPE},dispatch)
}
const ViewCarTypeComponent = connect(mapStateToProps,mapDispatchToProps)(ViewCarType);

export default ViewCarTypeComponent;
