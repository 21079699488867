import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Emailvalidate} from '../../common/validate.js';
import { AC_AUTHORIZATION } from '../../actions/settings';

class Login extends React.Component{
  constructor(props){
    super(props);

    this.state=
    {
      email                 : '',
      password              : '',
      emailerror            : false,
      passworderror         : false,
      validmailerror        : "",
      validpassworderror    : false,
      emailvalue            : false,
      passwordvalue         : false,
      isLogin               : false
    }
    this.validlogin         = this.validlogin.bind(this)
    this.onChangevalue      = this.onChangevalue.bind(this)
  }
  ///validation()
  validlogin(){
    const mail              = this.state.email;
    const password          = this.state.password;
    const id                = this.props.SettingsReducer.settingId;
    var emailcheck=0;
    // eslint-disable-next-line no-unused-vars
    var passwordcheck=0;
    if(mail){
          if(!Emailvalidate(mail)){
            this.setState({emailcheckerror:true});
          }
          else{
            emailcheck=1;
            this.setState({emailcheckerror:false});
          }
    }
    if(mail){
      this.setState({emailerror:false})
    }
    else {
      this.setState({emailerror:true})
    }
    if(password){
        this.setState({passworderror:false});
    }
    else {
        this.setState({passworderror:true})
    }
   if(mail&& password&& emailcheck===1){
     this.props.authorization({password:password,email:mail},id)
    }
  }

//onchange values
  onChangevalue(event){
    const name        =event.target.name;
    const value       =event.target.value;

    //E-Mail
    if(name ==='email'){
     const isvalid=Emailvalidate(value);
     if(isvalid){
      this.setState({email:value,emailerror:false,validmailerror:"",emailvalue:true})
      }
    else{
      this.setState({email:value,emailerror:true,validmailerror:"Entrez une adresse mail valide",emailvalue:false})
      }
     this.setState({email:value,emailerror:false})
     }
     //Password
     if(name ==="password"){
       this.setState({password:value,passworderror:false});
       }
    if(name==="remember"  ){
      if(event.target.checked){
          if(this.state.email){
           localStorage.setItem("email",this.state.email);
          }
          if(this.state.password){
           localStorage.setItem("password",this.state.password);
          }
      }
      else{
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
    }
  }
  render(){
    if (this.state.isLogin) {
      return <Redirect to='/'/>;
    }
    if(this.props.SettingsReducer.auth){
           localStorage.setItem("isAuth",true);
           this.setState({isLogin:true});
           window.location.reload(false);
          //  this.props.history.push("/dashboard");
    }
    return(
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <b>MonBolide</b>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Connectez-vous pour gérer Monbolide - Fort</p>
                <div className="input-group mb-3">
                  <input type="email" className="form-control" placeholder="Email" name="email"  onChange={(e)=>this.onChangevalue(e)}/>
                   <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                {this.state.emailerror?<label style={{color:"red"}}>Entrez votre adresse mail</label>:""}
                {this.state.validmailerror!=="" && !this.state.emailerror?<label style={{color:"red"}}>{this.state.validmailerror}</label>:""}
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Password" name="password" onChange={(e)=>this.onChangevalue(e)}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                {this.state.passworderror?<label style={{color:"red"}}>Entrez votre mot de passe</label>:""}
                {this.state.validpassworderror?<label style={{color:"red"}}>Mot de passe incorrect</label>:""}
                <div className="row">
                  <div className="col-7">
                    <div className="icheck-primary">
                      <input type="checkbox" name="remember" value="" othersProps onChange={(e)=>this.onChangevalue(e)} id="remember"/>
                      <label htmlFor="remember" >
                        Se souvenir de moi
                      </label>
                    </div>
                  </div>
                  <div className="col-5">
                    <button type="button" value="submit" className="btn btn-primary btn-block btn-flat" onClick={this.validlogin}>Se connecter</button>
                  </div>
                </div>
              <p className="mb-1">
              <Link to="/forgotPassword">Mot de passe oublié ?</Link>
              </p>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({authorization:AC_AUTHORIZATION},dispatch)
}
const LoginComponent = connect(mapStateToProps,mapDispatchToProps)(Login);

export default LoginComponent;
