import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';
// Action types for ListCar management
const LIST_CAR            = 'LIST_CAR';
const VIEW_CAR            = 'VIEW_CAR';
const EDIT_CAR            = 'EDIT_CAR';
const UPDATE_CAR_DATA     = 'UPDATE_CAR_DATA';
const DELETE_CAR          = 'DELETE_CAR';
// const UPDATE_HOST_DETAILS = 'UPDATE_HOST_DETAILS';
const GET_OWNER_INFO            = 'GET_OWNER_INFO'
const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_GET_OWNER_INFO(formData) {
  const id = { id : formData}

  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.GetOwner, id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: GET_OWNER_INFO, payload:data});
    });
  };
}


export function AC_LIST_CAR() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListCar)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_CAR, payload:data});
    });
  };
}

export function AC_VIEW_CAR(formData) {
  const id = { id : formData}
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewCar,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        
        dispatch({type: VIEW_CAR, payload:data});
    });
  };
}

export function AC_DELETE_CAR(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteCar,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_CAR, payload:data});
      }
    });
  };
}

export function AC_EDIT_CAR(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditCar,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_CAR, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_CAR_DATA, name:name,value:value})
  };
}

 export function AC_UPDATE_HOST_DETAILS(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateHostDetail,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
          dispatch({type: VIEW_CAR, payload:data});
        }
        else {
          Error(data.message)
        }
      }
    });
  };
}
