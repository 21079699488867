/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { Imagevalidation } from '../../common/validate';
import { AC_VIEW_BRAND,AC_EDIT_BRAND,AC_HANDLE_INPUT_CHANGE,AC_ADD_MODELOPTION,AC_DELETE_MODELOPTION,AC_LIST_MODELOPTION,AC_EDIT_MODELOPTION} from '../../actions/brandaction';
import API from '../../common/api';

class EditBrand extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
    brandname         : '',
    image             : '',
    status            : '',
    statuserror       : '',
    brandnameerror    : false,
    imageerror        : false,
    imagecheck        : false,
    imagevalue        : false,
    imgSrc            : 'dist/img/audi.png',
    optionname        : '',
    optiondesc        : '',
    optionmandatory   : false,
    optionnameError   : false,
    optiondescError   : false,
    isNewRow          : false,
    modeloptionId     : '',
  }
  this.validateForm      = this.validateForm.bind(this);
  this.addNewModelOption = this.addNewModelOption.bind(this);
  this.handleModelChange = this.handleModelChange.bind(this);
  this.addModelOption    = this.addModelOption.bind(this);

 }
 componentWillMount() {
 const id = this.props.match.params.id;
 this.props.ViewBrand(id);
}

 validateForm() {
   const brandInfo    = this.props.BrandReducer.brandInfo;
   const brandname    = brandInfo.brandname;
   const logo         = brandInfo.logo;
   const imagecheck   = this.state.imagecheck;
   const status       = brandInfo.status;
   const id           = brandInfo.id;
  //const statuserror = this.state.statuserror;
    if(brandname) {
      this.setState({brandnameerror : false})
   }
   else{
      this.setState({brandnameerror : true})
   }
   const brand_name   =  brandname.charAt(0).toUpperCase() + brandname.slice(1);
    if(brandname && !imagecheck) {
      const formData = new FormData();
      formData.append('logo', logo);
      formData.append('id', id);
      formData.append('brandname', brand_name);
      formData.append('status', status);
      this.props.EditBrand(formData);
   }
 }
 //Onchange events
 onChangeValue(event){
   const name=event.target.name;
   var value=event.target.value;
   if(name ==='brandname'){
    this.setState({brandname:value,brandnameerror:false})
  }

   // Image
   if(name==='image'){
     this.setState({ imagedata : event.target.files[0] })
        var file            =  event.target.files[0];
        const imgvalidate   =  Imagevalidation(file);
        if(imgvalidate){
        var reader          = new FileReader();
        // var url             = reader.readAsDataURL(file);
        reader.onloadend    = function(e){
        this.setState({imgSrc : [reader.result],imagevalue:true,imagecheck:false})
        }.bind(this);
        }
        else{
          this.setState({image:value,imagecheck:true,})
          this.setState({imgSrc : ""})
        }
        value = event.target.files[0];
   }
   this.props.HandleInputChange(name,value)
 }
//List Options
 ListOptionsRow() {
  this.setState({rowscount:this.state.rowscount+1})
 }
 deleteModeloption(optionid,brandid) {
  swal({
       title: "Are you sure?",
       text: "Once deleted, you will not be able to recover this imaginary file!",
       icon: "warning",
       buttons: true,
       dangerMode: true,
     })
     .then((willDelete) => {
       if (willDelete) {
         this.props.deleteModeloption({optionid : optionid,brandid: brandid});
       }
 });
}

// Add Model options
addModelOption() {
  const optionname        = this.state.optionname;
  const optiondesc        = this.state.optiondesc;
  var optionmandatory     = this.state.optionmandatory;
  const id                = this.props.BrandReducer.brandInfo.id;
  const optionid          = this.state.modeloptionId;
  if(optionname) {
    this.setState({optionnameError:false})
  } else {
    this.setState({optionnameError:true})
  }
  if(optiondesc) {
    this.setState({optiondescError:false})
  } else {
    this.setState({optiondescError:true})
  }

  if(optionid){
    if(optionname && optiondesc) {
      const modelFormData = {
        optionname      : optionname,
        optiondesc      : optiondesc,
        optionmandatory : optionmandatory,
        brandid         : id,
        modeloptionid   : optionid
      }
      this.setState({optionname:'',optiondesc:'',modeloptionId:'',optionmandatory:'false'});
      document.getElementById("optionmandatory").checked = false;
      this.props.editModelOption(modelFormData);
    }
  } else {
    if(optionname && optiondesc ) {
      const modelFormData = {
        optionname      : optionname,
        optiondesc      : optiondesc,
        optionmandatory : optionmandatory,
        brandid        : id
      }
      this.setState({optionname:'',optiondesc:'',optionmandatory:'false'});
      document.getElementById("optionmandatory").checked = false;
      this.props.addModelOption(modelFormData);
    }
  }
}

addNewModelOption() {
  this.setState({isNewRow:true})
}

handleModelChange(event) {
  const name        =  event.target.id;
  const value       =  event.target.value;
  if(name === 'optionname') {
    if(value.length > 0 ) {
      this.setState({optionnameError:false});
    } else {
      this.setState({optionnameError:true});
    }
  }
  if(name === 'optiondesc') {
    if(value.length > 0 ) {
      this.setState({optiondescError:false});
    } else {
      this.setState({optiondescError:true});
    }
  }

  if( name === 'optionmandatory'){
    this.setState({optionmandatory:event.target.checked});
  } else {
    this.setState({[name]:value});
  }
}
setValue(e,optionid){
  const modeloptionList   = this.props.BrandReducer.modeloptionList;
  const id=optionid;
  if(!e.target.getAttribute("delete")){
    const selectedOption = modeloptionList.filter((modelOption)=>{
      return  modelOption._id === id;
    });
    this.setState({optionname:selectedOption[0].name,optiondesc:selectedOption[0].description,modeloptionId:id,optionmandatory:selectedOption[0].mandatory})
    this.setState({optiondescError:false,optionnameError:false,optionvalueError:false});
    if(selectedOption[0].mandatory){
      document.getElementById("optionmandatory").checked = true;
    }
 }
}


render() {

  const brandInfo  = this.props.BrandReducer.brandInfo;
  const brandname = brandInfo.brandname;
  const logo   = brandInfo.logo;
  const status  = brandInfo.status;
  const LIVEURL = API.LIVEURL;
  const modeloptionList   = this.props.BrandReducer.modeloptionList;
  const modeloptionCount  = this.props.BrandReducer.modeloptionCount;
  var modeloptionlistArray    =[];
   for(var i = 0; i < modeloptionCount; i++)  {
     const optionid=modeloptionList[i]._id;
     if(modeloptionList[i].mandatory === true)  {
       var mandatory=true
     } else {
       // eslint-disable-next-line no-redeclare
       var mandatory=false
     }
     modeloptionlistArray.push(
       <tr onClick={(e)=> this.setValue(e,optionid)} key={i} >
         <td >
           {modeloptionList[i].name}
         </td >
         <td >
         {modeloptionList[i].description}
         </td>
         <td >
         { mandatory?<span className="badge badge-success"  style={{width:"59px"}}>Required</span>:<span className="badge badge-success" style={{backgroundColor:"#dc3545"}}>optional</span>}
         </td>
         <td >
         <a href="javascript:void(0)" className="btn btn-danger" delete='delete'  onClick={(e)=>this.deleteModeloption(optionid,brandInfo.id)}><i className="fas fa-trash"></i></a>
         </td>
       </tr>
     )
    }

  return(
    <div className="content-wrapper">
    <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
          <h1>Edit Brand</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Edit Brand</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Brand</h3>
              </div>
              <form>
              <div className="card-body">
                    <div className="form-group">
                       <label>Brand Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="brandname" placeholder="Enter Brand Name"  value={brandname} pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.brandnameerror ? <label style={{color:"red"}}>Brand Name is Required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Image</label><span style={{color:"red"}}>*</span>
                    <div className="input-group">
                       <div className="custom-file">
                           <input type="file" name="image" className="custom-file-input" id="exampleInputFile" value='' onChange={(e)=>this.onChangeValue(e)}/>
                           <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                       </div>
                       <div className="input-group-append">
                           <button className="input-group-text" id="">Upload</button>
                       </div>
                    </div>
                        {this.state.imagecheck? <label style={{color:'red'}}>Image format is Invalid</label>:""}
                    <div>
                      {this.state.imagevalue? <img src={this.state.imgSrc} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/> : <img src={LIVEURL+logo} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>}
                     </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                    <select name = "status" className="form-control"  onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1" selected={status === 1}>Active</option>
                       <option value = "0" selected={status === 0} >Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                       <button type="button" className="btn btn-primary" onClick={this.validateForm}>Update</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-header">
            <div className="container-fluid">
              <section className="content">
                <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Options</h3>
                        <div className="card-tools">
                          <div className="input-group input-group-sm">
                            <div className="input-group-append">
                              <button type="button" className="btn btn-primary" onClick={this.addNewModelOption}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body table-responsive p-0" style={{display: this.state.isNewRow || modeloptionCount>0?"block":"none"}}>
                        <table className="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                              <th>Mandatory</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input type="text" className="form-control" id="optionname" placeholder="Name" value={this.state.optionname} onChange={(e)=>this.handleModelChange(e)}/>
                                {this.state.optionnameError?<span style={{color:"red"}}>Name is required</span>:""}
                              </td>
                              <td>
                                <input type="text" className="form-control" id="optiondesc" placeholder="Description" value={this.state.optiondesc} onChange={(e)=>this.handleModelChange(e)}/>
                                {this.state.optiondescError?<span style={{color:"red"}}>Description is required</span>:""}
                              </td>
                              <td>
                                <input type="checkbox" className="form-control" id="optionmandatory" style={{width: "27px",marginLeft:"30px"}} onChange={(e)=>this.handleModelChange(e)}/>
                              </td>
                              <td className="text-right py-0 align-middle">
                                <div className="btn-group btn-group-sm">
                                  <a href="javascript:void(0)" className="btn btn-primary" onClick={this.addModelOption}><i className="fas fa-plus"></i></a>
                                </div>
                              </td>
                            </tr>
                            {modeloptionlistArray}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </section>
            </div>
          </section>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    BrandReducer : state.BrandReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({addModelOption: AC_ADD_MODELOPTION,editModelOption: AC_EDIT_MODELOPTION,ViewBrand: AC_VIEW_BRAND,EditBrand:AC_EDIT_BRAND,HandleInputChange:AC_HANDLE_INPUT_CHANGE,deleteModeloption:AC_DELETE_MODELOPTION,ListModeloptions:AC_LIST_MODELOPTION},dispatch)
}
const EditBrandComponent = connect(mapStateToProps,mapDispatchToProps)(EditBrand);

export default EditBrandComponent;
