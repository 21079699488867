import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import API from '../../common/api';
import { AC_VIEW_USER, AC_ADD_LICENSE } from '../../actions/useraction';
import { Image } from 'antd';
import 'antd/dist/antd.css';
import './ViewUser.css'
import FileSaver from "file-saver";

class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extend: false,
      decline: "",
      declineerror: false,
      visible: false,
    }
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    this.validateform = this.validateform.bind(this);
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewUser(id);
  }

  saveFile = (path, filename) => {
    console.log(path + ' ' + filename)
    FileSaver.saveAs(
      path,
      filename
    );
  }

  accept() {
    // const declineerror = this.state.declineerror;
    const userInfo = this.props.UserReducer.userInfo;
    const data = {
      status: 1,
      id: userInfo._id,
      email: userInfo.email
    }
    swal({
      title: "Are you sure?",
      text: "The profile status will be set to verified!",
      buttons: true,
      dangerMode: false,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.props.AddLicense(data);
        }
      });
  }
  decline() {
    this.setState({ extend: true })
  }
  validateform() {
    const decline = this.state.decline;
    // const declineerror = this.state.declineerror;
    const userInfo = this.props.UserReducer.userInfo;
    if (decline) {
      this.setState({ declineerror: false })
    } else {
      this.setState({ declineerror: true })
    }
    if (decline) {
      var data = {
        comments: decline,
        status: 0,
        id: userInfo?._id,
        email: userInfo?.email
      }
      swal({
        title: "Are you sure?",
        text: "The profile status will be set to not accepted!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.props.AddLicense(data);
            this.setState({ extend: false })
          }
        });
    }
  }
  onChangeValue(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'decline') {
      if (value) {
        this.setState({ decline: value, declineerror: false })
      } else {
        this.setState({ decline: value, declineerror: true })
      }
    }
  }
  render() {

    const userInfo = this.props.UserReducer.userInfo;
    const firstname = userInfo.firstname;
    const lastname = userInfo.lastname;
    const gender = userInfo.gender;
    const age = userInfo.age;
    const country = userInfo.country;
    const mobile = userInfo.mobile;
    const status = userInfo.status;
    // const nomProprietaire = userInfo.ownercard;
    const iban = userInfo.iban;
    const mobilemoney = userInfo.mobilemoney;
    // const LIVEURL = API.LIVEURL;
    const IMAGEURL = API.IMAGEURL
    // var licenseimage = "/noimage.jpg";
  
    let imageList = []
    let licenseimageRecto = ''
    let licenseimageVerso = ''
    let idimageRecto = ''
    let idimageVerso = ''
    let billingimage = ''
    let selfie = ''
    if (userInfo?.attachments?.licenseimage?.recto) {
      imageList.push(IMAGEURL + userInfo?.attachments?.licenseimage?.recto)
      licenseimageRecto = API.URL_TO_DOWNLOAD + userInfo?.attachments?.licenseimage?.recto
    }
    if (userInfo?.attachments?.licenseimage?.verso) {
      imageList.push(IMAGEURL + userInfo?.attachments?.licenseimage?.verso)
      licenseimageVerso = API.URL_TO_DOWNLOAD + userInfo?.attachments?.licenseimage?.verso
    }
    if (userInfo?.attachments?.idimage?.recto) {
      imageList.push(IMAGEURL + userInfo?.attachments?.idimage.recto)
      idimageRecto = API.URL_TO_DOWNLOAD + userInfo?.attachments?.idimage?.recto

    }
    if (userInfo?.attachments?.idimage?.verso) {
      imageList.push(IMAGEURL + userInfo?.attachments?.idimage.verso)
      idimageVerso = API.URL_TO_DOWNLOAD + userInfo?.attachments?.idimage?.verso
    }
    if (userInfo?.attachments?.billingimage) {
      imageList.push(IMAGEURL + userInfo?.attachments?.billingimage)
      billingimage = API.URL_TO_DOWNLOAD + userInfo?.attachments?.billingimage
    }
    if (userInfo?.attachments?.selfie) {
      imageList.push(IMAGEURL + userInfo?.attachments?.selfie)
      selfie = API.URL_TO_DOWNLOAD + userInfo?.attachments?.selfie
    }

    let previewImageList = []
    console.log(imageList)
    if(imageList.length > 0){
      previewImageList = imageList.map(imageScr => (
        // <Image key={imageScr} src={imageScr} style={{ height: "300px", width: "450px", padding: '10px' }} />
        <Image key={imageScr} src={imageScr}  />
  
      ))
    }
    
    // console.log(previewImageList)

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Information Utilisateur</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Information Utilisateur</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title text-white">Information utilisateur</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="firstname">Nom</label>
                      <input type="text" name="firstname" id="firstname" className="form-control" disabled value={firstname} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastname">Prénom</label>
                      <input type="text" name="lastname" id="lastname" className="form-control" disabled value={lastname} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="gender">Genre</label>
                      <input type="text" name="gender" id="gender" className="form-control" disabled value={gender} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="age">Age</label>
                      <input type="text" name="age" id="age" className="form-control" disabled value={age} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="country">Pays</label>
                      <input type="text" name="country" id="country" className="form-control" disabled value={country} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Titulaire du compte</label>
                      {/* <input type="text" name="mobile" id="mobile" className="form-control" disabled value={nomProprietaire} /> */}
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Iban</label>
                      <input type="text" name="mobile" id="mobile" className="form-control" disabled value={iban} />
                    </div>

                    <div className="form-group">
                      <label htmlFor="mobile">Mobilemoney</label>
                      <input type="text" name="mobile" id="mobile" className="form-control" disabled value={mobilemoney} />
                    </div>



                    <div className="form-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input type="text" name="mobile" id="mobile" className="form-control" disabled value={mobile} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select name="status" id="status" value={status} disabled className="form-control" >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title text-white">Voir les pièces jointes</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      {/* <div className='lead mb-3'>Pièces Jointes</div> */}
                      {imageList.length === 0 &&
                        <div>
                          <img src="https://monbolide.s3.eu-north-1.amazonaws.com/monbolideimage/noimage_o5qglm.jpg" style={{width: "100%"}} alt="Bonjour" />
                        </div>
                      }

                      {imageList.length > 0 &&
                        <><Image
                          preview={{ visible: false }}

                          style={{ width: "100%", padding: '10px' }}
                          src={imageList[0]}
                          onClick={() => this.setState({ visible: true })} />
                          <div style={{ display: 'none' }}>
                            <Image.PreviewGroup preview={{ visible: this.state.visible, onVisibleChange: vis => this.setState({ visible: vis }) }}>
                              {previewImageList}
                            </Image.PreviewGroup>

                          </div></>}
                      <div className="container pt-2">
                        <div className="row">
                          {licenseimageRecto && licenseimageVerso && <div className="col-6">

                            <div className="card text-center">
                              <div className="card-header" style={{ textTransform: 'uppercase' }} >
                                Permis de conduire
                              </div>
                              <div className="card-body">
                                {/* <h5 className="card-title">Permis</h5> */}
                                <div className="row">
                                  <div className="col-6">
                                    <a href={licenseimageRecto} className="btn btn-outline-secondary onHoverBtn" download="Permis_de_conduire_recto"><i className="fas fa-download iconColor"></i> Recto</a>
                                  </div>
                                  <div className="col-6">
                                    <a href={licenseimageVerso} className="btn btn-outline-secondary onHoverBtn" download="Permis_de_conduire_verso"><i className="fas fa-download iconColor"></i> Verso</a>
                                  </div>
                                </div>
                                <div>

                                </div>

                              </div>

                            </div>
                          </div>}

                          {idimageRecto && idimageVerso &&
                            <div className="col-6">
                              <div className="card text-center">
                                <div className="card-header" style={{ textTransform: 'uppercase' }}>
                                  Pièce d'identité
                                </div>
                                <div className="card-body">
                                  {/* <h5 className="card-title">Permis</h5> */}
                                  <div className="row" style={{ justifyContent: 'center' }}>
                                    <div className="col-6">
                                      <a href={idimageRecto} className="btn btn-outline-secondary onHoverBtn" download="carte_identite_recto"><i className="fas fa-download iconColor"></i> Recto</a>
                                    </div>
                                    <div className="col-6">
                                      <a href={idimageVerso} className="btn btn-outline-secondary onHoverBtn" download="carte_identite_vecto"><i className="fas fa-download iconColor"></i> Recto</a>
                                    </div>

                                  </div>
                                  <div>

                                  </div>

                                </div>

                              </div>
                            </div>}
                          {billingimage && <div className="col-6">
                            <div className="card text-center">
                              <div className="card-header" style={{ textTransform: 'uppercase' }}>
                                FACTURE CIE / SODECI
                              </div>
                              <div className="card-body">
                                {/* <h5 className="card-title">Permis</h5> */}
                                <div className="row">
                                  <div className="col-6">
                                    <a href={billingimage} className="btn btn-outline-secondary onHoverBtn" download><i className="fas fa-download iconColor"></i> Recto</a>
                                  </div>

                                </div>
                                <div>

                                </div>

                              </div>

                            </div>
                          </div>}
                          {selfie && <div className="col-6">
                            <div className="card text-center">
                              <div className="card-header" style={{ textTransform: 'uppercase' }}>
                                SELFIE
                              </div>
                              <div className="card-body">
                                {/* <h5 className="card-title">Permis</h5> */}
                                <div className="row">
                                  <div className="col-6">
                                    <a href={selfie} className="btn btn-outline-secondary onHoverBtn" download><i className="fas fa-download iconColor"></i> Recto</a>
                                  </div>

                                </div>
                                <div>

                                </div>

                              </div>

                            </div>
                          </div>}

                        </div>
                      </div>

                    </div>
                    {userInfo?.verification === 2 ? <div className="callout callout-warning">
                      <h6>Pending!</h6>
                    </div> : ""}
                    {userInfo?.verification === 0 ? <div className="callout callout-danger">
                      <h6>Declined!</h6>
                    </div> : ""}
                    {userInfo.verification === 1 ? <div className="callout callout-success">
                      <h6>Accepted!</h6>
                    </div> : ""}
                    <div style={{ display: userInfo.attachments ? "block" : "none" }}>
                      <div className="form-group">
                        <button type="button" className="btn btn-success" onClick={this.accept}>Accept</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {!this.state.extend ? <button type="button" className="btn btn-warning" onClick={this.decline}>decline</button> : ""}
                      </div>
                      {!this.state.extend ? "" : <div className="form-group">
                        <input type="text" name="decline" id="decline" className="form-control" placeholder="decline comments" value={this.state.decline} onChange={(e) => this.onChangeValue(e)} />
                        {this.state.declineerror ? <label className='text-danger' >Decline comments is Required</label> : ""}
                      </div>}
                      <div className="form-group" style={{ display: this.state.extend ? "block" : "none" }}>
                        <button type="button" className="btn btn-warning" onClick={this.validateform}>Decline and Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    UserReducer: state.UserReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewUser: AC_VIEW_USER, AddLicense: AC_ADD_LICENSE }, dispatch)
}
const ViewUserComponent = connect(mapStateToProps, mapDispatchToProps)(ViewUser);

export default ViewUserComponent;
